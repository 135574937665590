import { CampaignMemberStatus, VisibilityState } from "@/models";
import { useServiceCall } from "@hooks/useServiceCall";
import { FetchOptions, fetchOutreachService } from "@utils/requests";
import { UUID } from "@utils/text";

// Types
export type FilteredCompany = {
  id: UUID;
  name: string;
  websiteUrl?: string;
  summary?: string;
};

export type FilteredContact = {
  id: UUID;
  firstName?: string;
  lastName?: string;
  photoUrl?: string;
  title?: string;
  linkedinUrl?: string;
  summary?: string;
  company?: FilteredCompany;
};

export type FilteredCampaignMember = {
  id: UUID;
  contactId: UUID;
  updatedTime: string;
  status: CampaignMemberStatus;
  campaignId: UUID;
  contact: FilteredContact;
  visibility: VisibilityState;
};

export type Page<T> = {
  items: T[];
  total: number;
  page: number;
  size: number;
};

// Request params type
export type GetFilteredMembersParams = {
  campaignIds?: UUID[];
  statuses?: string[];
  offset?: number;
  first?: number;
};

// Service function
export const getFilteredMembers = async ({
  campaignIds,
  statuses,
  offset = 0,
  first = 10,
}: GetFilteredMembersParams) => {
  const queryParams = new URLSearchParams();

  if (campaignIds?.length) {
    campaignIds.forEach(id => queryParams.append('campaign_ids', id));
  }

  if (statuses?.length) {
    statuses.forEach(status => queryParams.append('statuses', status));
  }

  queryParams.append('offset', offset.toString());
  queryParams.append('first', first.toString());

  const options: FetchOptions = {
    method: 'GET',
  };

  const url = `/campaign-members/filtered?${queryParams.toString()}`;
  const response = await fetchOutreachService(
    url,
    options
  );

  return response;
};

// Hook
export const useGetFilteredMembers = () =>
  useServiceCall<Page<FilteredCampaignMember>, GetFilteredMembersParams>({
    serviceCall: getFilteredMembers,
  });
