import { ROUTE_BILLING } from "@/AuthenticatedAppRoutes";
import { TrackedEventName } from "@/third-party/tracking";

import { ChargedPer } from "@/models";
import ProfileAvatar from "@components/ProfileAvatar";
import ReferralsTable from "@components/ReferralsTable";
import Tracked from "@components/Tracked";
import {
  ArrowRightIcon,
  AutoPilotIcon,
  CopyIcon,
  DownloadIcon,
  SignOutIcon,
  WarningIcon,
} from "@components/icons";
import SectionCard from "@components/layout/SectionCard";
import NavButton from "@components/layout/navigation/NavButton";
import Page from "@components/layout/page/Page";
import PageContent from "@components/layout/page/PageContent";
import { useAppContext } from "@context/AppContext";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Divider,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Stack,
  TextField,
  Tooltip,
  Typography,
  Zoom,
} from "@mui/material";
import { useGetUnsubscribeList } from "@services/campaigns/getUnsubscribeList";
import { useUpdateLawyer } from "@services/lawyers/updateLawyer";
import { getFullName } from "@utils/text";
import { useEffect, useState } from "react";
import AutoPilotControl from "./AutoPilotControl";
import MeetingAvailability from "./MeetingAvailability";
import PauseAccountDialogButton from "./PauseAccountDialogButton";

const ProfilePage = () => {
  const { profile, lawyerId, lawyerPublicId, isManaged, fetchProfile, logOut } =
    useAppContext();

  // Website url
  const [websiteUrl, setWebsiteUrl] = useState<string | null>("");
  // LinkedIn url
  const [linkedinUrl, setLinkedinUrl] = useState<string | null>("");
  // Leads per day
  const [leadsPerMonth, setLeadsPerMonth] = useState<number | null>(null);

  const [copyTooltipText, setCopyTooltipText] = useState('Copy link');

  const [
    getUnsubscribeList,
    { data: unsubscribeListResponse, loading: isLoadingUnsubscribeList },
  ] = useGetUnsubscribeList();

  const downloadList = () => {
    if (unsubscribeListResponse) {
      const downloadUrl = window.URL.createObjectURL(unsubscribeListResponse);
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", "Kula_Unsubscribed_List.csv");
      document.body.appendChild(link);
      link.click();
      link.remove();
    }
  };

  useEffect(() => {
    if (unsubscribeListResponse) {
      downloadList();
    }
  }, [unsubscribeListResponse]);

  // Initialise website url
  useEffect(() => {
    setWebsiteUrl(profile?.lawyer?.websiteUrl || null);
  }, [profile?.lawyer?.websiteUrl]);

  // Initialise linkedin url
  useEffect(() => {
    setLinkedinUrl(profile?.lawyer?.linkedinUrl || null);
  }, [profile?.lawyer?.linkedinUrl]);

  // Initialise leads per day
  useEffect(() => {
    setLeadsPerMonth(profile?.lawyer?.leadsPerMonth || null);
  }, [profile?.lawyer?.leadsPerMonth]);

  // Update lawyer
  const [patchLawyer, { loading: isPatchingLawyer }] = useUpdateLawyer();

  const handleSaveProfile = async () => {
    if (!lawyerId) return;
    if (!showSaveButton) return;

    await patchLawyer(
      {
        lawyerId: lawyerId,
        websiteUrl,
        linkedinUrl,
        leadsPerMonth,
      },
      {
        successMessage: "Profile updated successfully",
        track: {
          name: TrackedEventName.PROFILE_UPDATED,
        },
      }
    );

    fetchProfile?.();
  };

  const handleGetUnsubscribedList = async () => {
    if (!lawyerId) return;

    await getUnsubscribeList({
      lawyerId: lawyerId,
    });
  };

  const copyReferralLink = () => {
    if (referralLink) {
      navigator.clipboard.writeText(referralLink);
      setCopyTooltipText('Link copied to clipboard');
      setTimeout(() => setCopyTooltipText('Copy link'), 1500); // Reset after 1.5 seconds
    }
  };

  const openReferralLink = () => {
    if (referralLink) {
      window.open(referralLink, '_blank');
    }
  };

  // Show save button
  const showSaveButton =
    (websiteUrl && websiteUrl !== profile?.lawyer?.websiteUrl) ||
    (linkedinUrl && linkedinUrl !== profile?.lawyer?.linkedinUrl) ||
    (leadsPerMonth && leadsPerMonth !== profile?.lawyer?.leadsPerMonth);

  // Referral link
  const referralLink = lawyerPublicId
    ? `https://www.bookmeetings.pro/referrals/${lawyerPublicId}`
    : null;

  return (
    <>
      <Page>
        <PageContent>
          <SectionCard
            withBackButton
            skinny
            title={getFullName(profile?.firstName, profile?.lastName) || ""}
            titleIcon={<ProfileAvatar size={55} />}
            titleAction={
              <LoadingButton
                variant="contained"
                color="primary"
                disabled={!showSaveButton}
                loading={isPatchingLawyer}
                onClick={handleSaveProfile}
              >
                Save Profile
              </LoadingButton>
            }
          >
            <br />
            <Stack direction="column" spacing={2}>
              <Stack
                direction={{
                  xs: "column",
                  sm: "row",
                }}
                alignItems={"flex-start"}
                spacing={2}
              >
                <Typography
                  width={300}
                  color="text.secondary"
                  variant="subtitle1"
                >
                  Company Name
                </Typography>
                <Typography variant="body1">
                  {profile?.lawyer?.firmName}
                </Typography>
              </Stack>
              <Divider />
              <Stack
                direction={{
                  xs: "column",
                  sm: "row",
                }}
                alignItems={"flex-start"}
                spacing={2}
              >
                <Typography
                  width={300}
                  color="text.secondary"
                  variant="subtitle1"
                >
                  Email
                </Typography>
                <Typography variant="body1"> {profile?.email}</Typography>
              </Stack>
              <Divider />
              <Stack
                direction={{
                  xs: "column",
                  sm: "row",
                }}
                alignItems={"flex-start"}
                spacing={2}
              >
                <Stack
                  direction={"row"}
                  spacing={1}
                  alignItems={"center"}
                  width={300}
                >
                  {!websiteUrl && (
                    <WarningIcon color="error" fontSize="small" />
                  )}
                  <Typography
                    color={!websiteUrl ? "error" : "text.secondary"}
                    variant="subtitle1"
                  >
                    Website
                  </Typography>
                </Stack>
                <Box width={300}>
                  <TextField
                    size="small"
                    variant="outlined"
                    value={websiteUrl || ""}
                    onChange={(e) => setWebsiteUrl(e.target.value)}
                    label="Your Website URL"
                    fullWidth
                  />
                </Box>
              </Stack>
              <Divider />
              <Stack
                direction={{
                  xs: "column",
                  sm: "row",
                }}
                alignItems={"flex-start"}
                spacing={2}
              >
                <Stack
                  direction={"row"}
                  spacing={1}
                  alignItems={"center"}
                  width={300}
                >
                  {!linkedinUrl && (
                    <WarningIcon color="error" fontSize="small" />
                  )}
                  <Typography
                    color={!linkedinUrl ? "error" : "text.secondary"}
                    variant="subtitle1"
                  >
                    Linkedin
                  </Typography>
                </Stack>
                <Box width={300}>
                  <TextField
                    size="small"
                    variant="outlined"
                    value={linkedinUrl || ""}
                    onChange={(e) => setLinkedinUrl(e.target.value)}
                    label="Your Linkedin URL"
                    fullWidth
                  />
                </Box>
              </Stack>
              <Divider />
              <Stack
                direction={{
                  xs: "column",
                  sm: "row",
                }}
                alignItems={{
                  xs: "flex-start",
                  sm: "center",
                }}
                spacing={2}
              >
                <Stack direction={"column"} spacing={1} width={300}>
                  <Stack direction={"row"} spacing={1} alignItems={"center"}>
                    <AutoPilotIcon color="primary" />
                    <Typography color="text.secondary" variant="subtitle1">
                      Auto Pilot Mode
                    </Typography>
                  </Stack>
                  <Typography variant="caption" color={"text.secondary"}>
                    You can let Kular to manage your campaigns automatically.
                  </Typography>
                </Stack>

                <AutoPilotControl />
              </Stack>
              <Divider />

              <Stack
                direction={{
                  xs: "column",
                  sm: "row",
                }}
                alignItems={{
                  xs: "flex-start",
                  sm: "center",
                }}
                spacing={2}
              >
                <Stack direction={"column"} spacing={1} width={300}>
                  <Typography color="text.secondary" variant="subtitle1">
                    Payments
                  </Typography>
                  <Typography variant="caption" color={"text.secondary"}>
                    You can manage and view your payments
                  </Typography>
                </Stack>

                <NavButton
                  to={ROUTE_BILLING}
                  title="Payments"
                  label="Go to Payments"
                />
              </Stack>
              <Divider />

              {profile?.lawyer?.chargedPer === ChargedPer.MEETING ? (
                <>
                  <Stack
                    direction={{
                      xs: "column",
                      sm: "row",
                    }}
                    alignItems={{
                      xs: "flex-start",
                      sm: "center",
                    }}
                    spacing={2}
                  >
                    <Stack direction={"column"} spacing={1} width={300}>
                      <Typography color="text.secondary" variant="subtitle1">
                        Meeting Availability
                      </Typography>
                      <Typography variant="caption" color={"text.secondary"}>
                        You can manage your meeting availability
                      </Typography>
                    </Stack>

                    <MeetingAvailability />
                  </Stack>

                  <Divider />
                </>
              ) : null}

              {!!referralLink && (
                <>
                  <Stack
                    direction={{
                      xs: "column",
                      sm: "row",
                    }}
                    alignItems="center"
                    spacing={2}
                  >
                    <Stack direction={"column"} spacing={1} width={300}>
                      <Typography color="text.secondary" variant="subtitle1">
                        Referrals
                      </Typography>
                      <Typography variant="caption" color={"text.secondary"}>
                        Receive one free lead for each customer you refer to
                        Kular. The credit will be issued as soon as they sign up
                        for a Kular free trial.
                      </Typography>
                    </Stack>

                    <Box width={300}>
                      <OutlinedInput
                        fullWidth
                        readOnly
                        value={referralLink}
                        size="small"
                        onClick={(e) => {
                          (e.target as HTMLInputElement).select();
                          copyReferralLink();
                        }}
                        endAdornment={
                          <InputAdornment position="end">
                            <Tooltip
                              title={copyTooltipText}
                              arrow
                              TransitionComponent={Zoom}
                              TransitionProps={{ timeout: 300 }}
                            >
                              <IconButton
                                onClick={(e) => {
                                  e.stopPropagation();
                                  copyReferralLink();
                                }}
                                edge="end"
                                size="small"
                              >
                                <CopyIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Visit link" arrow>
                              <IconButton
                                onClick={(e) => {
                                  e.stopPropagation();
                                  openReferralLink();
                                }}
                                edge="end"
                                size="small"
                              >
                                <ArrowRightIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          </InputAdornment>
                        }
                      />
                    </Box>
                  </Stack>
                  <ReferralsTable lawyerId={lawyerId} />
                  <Divider />
                </>
              )}
              <Stack
                direction={{
                  xs: "column",
                  sm: "row",
                }}
                alignItems={{
                  xs: "flex-start",
                  sm: "center",
                }}
                spacing={2}
              >
                <Stack direction={"column"} spacing={1} width={300}>
                  <Typography color="text.secondary" variant="subtitle1">
                    Pause Account
                  </Typography>
                  <Typography variant="caption" color={"text.secondary"}>
                    You can pause your account. This will stop all campaigns.
                  </Typography>
                </Stack>
                <PauseAccountDialogButton />
              </Stack>
              <Divider />
              <Stack
                direction={{
                  xs: "column",
                  sm: "row",
                }}
                alignItems={{
                  xs: "flex-start",
                  sm: "center",
                }}
                spacing={2}
              >
                <Stack direction={"column"} spacing={1} width={300}>
                  <Typography color="text.secondary" variant="subtitle1">
                    Unsubscribed Leads
                  </Typography>
                  <Typography variant="caption" color={"text.secondary"}>
                    You can download all of the leads that wanted to unsubscribe
                    from your campaigns.
                  </Typography>
                </Stack>
                <LoadingButton
                  variant="contained"
                  color="primary"
                  loading={isLoadingUnsubscribeList}
                  startIcon={<DownloadIcon />}
                  onClick={(e) => handleGetUnsubscribedList()}
                >
                  Download Unsubscribed
                </LoadingButton>
              </Stack>
              <Divider />
              <Stack direction="row" spacing={2} alignItems={"center"}>
                <Tracked onClick={{ name: TrackedEventName.USER_SIGNED_OUT }}>
                  <Button
                    variant="outlined"
                    color="error"
                    startIcon={<SignOutIcon />}
                    onClick={() => logOut?.()}
                  >
                    Sign Out
                  </Button>
                </Tracked>
              </Stack>
            </Stack>
          </SectionCard>
        </PageContent>
      </Page>
    </>
  );
};

export default ProfilePage;
